// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/jbritton/Code/projects/fluid-plumbing-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/jbritton/Code/projects/fluid-plumbing-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-print-coupon-js": () => import("/Users/jbritton/Code/projects/fluid-plumbing-website/src/pages/print-coupon.js" /* webpackChunkName: "component---src-pages-print-coupon-js" */)
}

exports.data = () => import("/Users/jbritton/Code/projects/fluid-plumbing-website/.cache/data.json")

